import React from "react";
import Image from "../../static/designer.jpg";
export default function svgheart(props) {
  return (
    <svg
      xmlnsDc="http://purl.org/dc/elements/1.1/"
      xmlnsCc="http://creativecommons.org/ns#"
      xmlnsRdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
      xmlnsSvg="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/2000/svg"
      id="svg8"
      version="1.1"
      viewBox="0 0 186.84123 160.77313"
      height={props.height}
      width={props.width}
      {...props}
    >
      <defs id="defs2" />
      <metadata id="metadata5">
        <rdfRdf>
          <ccWork rdfAbout>
            <dcFormat>image/svg+xml</dcFormat>
            <dcType rdfResource="http://purl.org/dc/dcmitype/StillImage" />
            <dcTitle />
          </ccWork>
        </rdfRdf>
      </metadata>
      <g transform="translate(-12.930497,-37.900149)" id="layer1">
        <clipPath id="myPath">
          <path
            id="path882"
            d="m 77.524866,39.753079 -11.358954,0.217157 -8.201835,0.317381 -11.743153,0.367495 -9.671817,0.367496 -4.927782,5.328687 -5.295277,6.063679 -5.295277,6.030267 -7.750816,8.853304 -0.15034,5.462321 v 8.101607 l -0.06682,9.671815 0.133635,10.289882 -0.08352,5.06141 4.109269,4.92778 5.445617,6.46458 5.779703,6.59822 4.493469,5.27858 4.911076,5.59595 6.447877,6.26413 6.431175,5.98016 7.266389,6.949 6.698443,6.34766 6.982418,6.78196 6.314241,5.89663 7.082642,6.78197 5.295278,4.9946 6.314242,5.87993 4.293021,4.02574 4.07586,3.842 3.95893,-3.4745 4.82755,-4.42665 6.11379,-5.62937 5.69619,-5.32868 5.82981,-5.27858 6.78197,-6.24742 4.72733,-4.40995 4.79414,-4.42665 4.14268,-3.74178 5.06142,-4.71062 5.06141,-4.67722 4.87767,-4.42665 5.49573,-5.16164 5.77971,-5.26187 4.79414,-4.47676 3.57473,-5.7463 3.70837,-5.93004 3.29075,-5.42891 3.97564,-6.44788 2.08804,-3.307461 -1.01896,-6.815373 -1.10249,-7.015824 -1.10249,-7.032529 -1.08578,-6.898897 c 0,0 -0.93544,-5.996858 -1.00226,-5.930042 -0.0668,0.06682 -0.88533,-5.061416 -0.88533,-5.061416 l -4.19279,-4.276312 -5.11153,-5.128234 -5.26187,-5.245166 -5.71289,-5.746295 -2.77292,-2.756214 -5.32868,0.01669 h -9.00365 l -12.12735,-0.01669 -8.20183,-0.0167 -3.14042,2.57247 -4.10927,3.390982 -5.14494,4.242903 -6.23072,5.144939 -5.3955,4.409948 -5.81312,4.81085 -5.3955,-4.359833 -5.946748,-4.860965 -6.080381,-5.044712 -5.278573,-4.242903 -4.2262,-3.491209 z"
            style={{
              fill: "none",
              stroke: "#000000",
              strokeWidth: "0.26458332px",
              strokeLinecap: "butt",
              strokeLinejoin: "miter",
              strokeOpacity: 1
            }}
          />
        </clipPath>

        <path
          id="path882"
          d="m 77.524866,39.753079 -11.358954,0.217157 -8.201835,0.317381 -11.743153,0.367495 -9.671817,0.367496 -4.927782,5.328687 -5.295277,6.063679 -5.295277,6.030267 -7.750816,8.853304 -0.15034,5.462321 v 8.101607 l -0.06682,9.671815 0.133635,10.289882 -0.08352,5.06141 4.109269,4.92778 5.445617,6.46458 5.779703,6.59822 4.493469,5.27858 4.911076,5.59595 6.447877,6.26413 6.431175,5.98016 7.266389,6.949 6.698443,6.34766 6.982418,6.78196 6.314241,5.89663 7.082642,6.78197 5.295278,4.9946 6.314242,5.87993 4.293021,4.02574 4.07586,3.842 3.95893,-3.4745 4.82755,-4.42665 6.11379,-5.62937 5.69619,-5.32868 5.82981,-5.27858 6.78197,-6.24742 4.72733,-4.40995 4.79414,-4.42665 4.14268,-3.74178 5.06142,-4.71062 5.06141,-4.67722 4.87767,-4.42665 5.49573,-5.16164 5.77971,-5.26187 4.79414,-4.47676 3.57473,-5.7463 3.70837,-5.93004 3.29075,-5.42891 3.97564,-6.44788 2.08804,-3.307461 -1.01896,-6.815373 -1.10249,-7.015824 -1.10249,-7.032529 -1.08578,-6.898897 c 0,0 -0.93544,-5.996858 -1.00226,-5.930042 -0.0668,0.06682 -0.88533,-5.061416 -0.88533,-5.061416 l -4.19279,-4.276312 -5.11153,-5.128234 -5.26187,-5.245166 -5.71289,-5.746295 -2.77292,-2.756214 -5.32868,0.01669 h -9.00365 l -12.12735,-0.01669 -8.20183,-0.0167 -3.14042,2.57247 -4.10927,3.390982 -5.14494,4.242903 -6.23072,5.144939 -5.3955,4.409948 -5.81312,4.81085 -5.3955,-4.359833 -5.946748,-4.860965 -6.080381,-5.044712 -5.278573,-4.242903 -4.2262,-3.491209 z"
          style={{
            fill: "none",
            stroke: "#000000",
            strokeWidth: "0.26458332px",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeOpacity: 1
          }}
        />
      </g>
      <g transform="translate(-12.930497,-37.900149)" id="layer2">
        <image
          width="250"
          height="250"
          x={props.xAxis}
          y={props.yAxis}
          preserveAspectRatio="xMinYMin meet"
          xlinkHref={props.image}
          clipPath="url(#myPath)"
        />
      </g>
    </svg>
  );
}
