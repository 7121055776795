import React from "react";
import "bulma/css/bulma.css";
import "../styles/carouselStyle.css";
import LeftSide from "../../static/leftCursor.svg";
import RightSide from "../../static/rightCursor.svg";

const ImageSlide = ({ url }) => {
  const styles = {
    backgroundImage: `url(${url})`,
    backgroundSize: "cover",
    backgroundPosition: "center"
  };

  return <div className="image-slide thatSlide" style={styles}></div>;
};

const Arrow = ({ direction, clickFunction, glyph }) => (
  <div>
    <div className={`slide-arrow ${direction}`} onClick={clickFunction}>
      <div className="sideArrow"></div>
    </div>
  </div>
);

class Carousel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentImageIndex: 0
    };
    this.nextSlide = this.nextSlide.bind(this);
    this.previousSlide = this.previousSlide.bind(this);
  }

  previousSlide() {
    const lastIndex = this.props.imgUrls.length - 1;
    const { currentImageIndex } = this.state;
    const shouldResetIndex = currentImageIndex === 0;
    const index = shouldResetIndex ? lastIndex : currentImageIndex - 1;

    this.setState({
      currentImageIndex: index
    });
  }

  nextSlide() {
    const lastIndex = this.props.imgUrls.length - 1;
    const { currentImageIndex } = this.state;
    const shouldResetIndex = currentImageIndex === lastIndex;
    const index = shouldResetIndex ? 0 : currentImageIndex + 1;

    this.setState({
      currentImageIndex: index
    });
  }

  render() {
    let carousel = "carousel";
    if (this.props.nullC) {
      carousel += " hideMe";
    }

    return (
      <div className={carousel}>
        <Arrow
          direction="left"
          clickFunction={this.previousSlide}
          glyph={LeftSide}
        />

        <ImageSlide url={this.props.imgUrls[this.state.currentImageIndex]} />

        <Arrow
          direction="right"
          clickFunction={this.nextSlide}
          glyph={LeftSide}
        />
      </div>
    );
  }
}
export default Carousel;
