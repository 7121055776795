import React from "react";
import { graphql } from "gatsby";
import SubCollection from "../components/subCollection";
import ItemCard from "../components/item";
import Carousel from "../components/carousel";
import Media from "react-media";

class SubCarousel extends React.Component {
  render() {
    let cond = "sizer centerMe";
    if (this.props.conditional) {
      cond += " hideMe";
    }
    if (!this.props.conditional) {
      return (
        <Media query="(max-width: 991px)">
          {matches =>
            matches ? (
              <div className={cond}>
                <Carousel
                  className="colCa"
                  imgUrls={this.props.imgsource}
                ></Carousel>
              </div>
            ) : (
              <div className={cond}>
                <Carousel
                  className="colCa"
                  imgUrls={this.props.imgsource}
                ></Carousel>
              </div>
            )
          }
        </Media>
      );
    } else {
      return (
        <div className="sizer centerMe hideMe">
          <Carousel
            nullC
            className="colCa"
            imgUrls={this.props.imgsource}
          ></Carousel>
        </div>
      );
    }
  }
}

export default function Template({ data }) {
  const { markdownRemark: post } = data;
  let {
    Cards: cards,
    Carousel: carousel,
    Description: description,
    heartImage,
    title,
    xAxis,
    yAxis,
    Youtube: youtube,
  } = post.frontmatter;
  cards = cards || [];
  carousel = carousel || [];

  const carouselNull = carousel.some(item => item.carouselImage == null);
  const source = carousel.map(item => {
    if (item.carouselImage != null) {
      return `${item.carouselImage}`;
    }
    return "null";
  });

  const cardSection = cards.map(item => {
    if (item.cardImage != null) {
      return (
        <ItemCard
          title={item.cardTitle}
          subtitle={item.cardSubtitle}
          image={item.cardImage}
        />
      );
    }
    return null;
  });

  if (
    description != null &&
    youtube === null
  ) {
    return (
      <SubCollection
        bigText
        title={title}
        xAxis={xAxis}
        yAxis={yAxis}
        noImage
        startImage={heartImage}
        textSection={description}
      >
        <SubCarousel
          conditional={carouselNull}
          imgsource={source}
        ></SubCarousel>
        <div className="cardHolder">{cardSection}</div>
      </SubCollection>
    );
  }
  if (
    description === null &&
    youtube != null
  ) {
    let link = `https://www.youtube.com/embed/${youtube}?loop=1&playlist=${youtube}`;

    return (
      <SubCollection
        bigText
        noDesc
        yesIframe
        video={link}
        title={title}
        xAxis={xAxis}
        yAxis={yAxis}
        noImage
        startImage={heartImage}
        textSection={description}
      >
        <SubCarousel
          conditional={carouselNull}
          imgsource={source}
        ></SubCarousel>
        <div className="cardHolder">{cardSection}</div>
      </SubCollection>
    );
  }
  if (
    description === null &&
    youtube === null
  ) {
    return (
      <SubCollection
        noDesc
        bigText
        title={title}
        xAxis={xAxis}
        yAxis={yAxis}
        noImage
        startImage={heartImage}
        textSection={description}
      >
        <SubCarousel
          conditional={carouselNull}
          imgsource={source}
        ></SubCarousel>
        <div className="cardHolder">{cardSection}</div>
      </SubCollection>
    );
  } else {
    let link = `https://www.youtube.com/embed/${youtube}?loop=1&playlist=${youtube}`;
    return (
      <SubCollection
        bigText
        yesIframe
        video={link}
        title={title}
        xAxis={xAxis}
        yAxis={yAxis}
        noImage
        startImage={heartImage}
        textSection={description}
      >
        <SubCarousel
          conditional={carouselNull}
          imgsource={source}
        ></SubCarousel>
        <div className="cardHolder">{cardSection}</div>
      </SubCollection>
    );
  }
}
export const postQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        title
        path
        Description
        heartImage
        xAxis
        yAxis
        Youtube
        belongsTo
        Carousel {
          carouselImage
        }
        Cards {
          cardImage
          cardTitle
          cardSubtitle
        }
      }
    }
  }
`;
