import React from "react";
import "bulma/css/bulma.css";
import "../styles/global.css";

import Layout from "../components/layout";

import Quotes from "../../static/quotes.svg";
import Media from "react-media";
import FullSvgHeart from "../components/fullsvgheart";

class SubCollection extends React.Component {
  render() {
    let className = "";
    if (this.props.noDesc) {
      className += " hideMe";
    }
    let imageClass = "colI";
    if (this.props.noImage) {
      imageClass += " ";
    }
    let thatIframe = "thatSubIframe";
    if (!this.props.yesIframe) {
      thatIframe += " hideFrame";
    }
    let nullCarousel = "colCa";
    if (this.props.nullCarousel) {
      nullCarousel += " hideMe";
    }
    let removeC = "sizer";
    if (this.props.nullC) {
      nullCarousel += " hideMe";
    }

    return (
      <Layout miniLogoColor={"white"}>
        <Media query="(min-width: 991px)">
          {matches =>
            matches ? (
              <div className="colW">
                <section className="hero is-fullheight myHero">
                  <div className="colC">
                    <div className="stackCol">
                      <div className="fixer">
                        <FullSvgHeart
                          bigText={this.props.bigText}
                          xAxis={this.props.xAxis}
                          yAxis={this.props.yAxis}
                          title={this.props.title}
                          className="hearty"
                          image={this.props.startImage}
                        ></FullSvgHeart>
                      </div>

                      <section className={className}>
                        <div className="sizer">
                          <div>
                            <hr className="firstHorz" />
                          </div>

                          <div className="testimonial">
                            <img className="firstQ" src={Quotes} alt="" />

                            <div className="sect2">
                              <p className="colP">{this.props.textSection}</p>
                            </div>

                            <img className="lastQ" src={Quotes} alt="" />
                          </div>
                          <hr className="lastHorz" />
                        </div>
                      </section>
                      <div>
                        <iframe
                          title="b"
                          className={thatIframe}
                          src={this.props.video}
                          frameborder="0"
                          allow="autoplay"
                          allowfullscreen
                        ></iframe>
                      </div>

                      <div className="">{this.props.children}</div>
                    </div>
                  </div>
                </section>
              </div>
            ) : (
              <section className="hero is-fullheight myHero miniHero">
                <div className="container is-fluid colC">
                  <div className="stackCol">
                    <div className="fixer">
                      <FullSvgHeart
                        bigText={this.props.bigText}
                        xAxis={this.props.xAxis}
                        yAxis={this.props.yAxis}
                        title={this.props.title}
                        className="hearty"
                        image={this.props.startImage}
                      ></FullSvgHeart>
                    </div>
                    <section className={className}>
                      <div className="sizer smallP">
                        <div className="testimonials">
                          <img className="firstQMini" src={Quotes} alt="" />
                          <p className="colP">{this.props.textSection}</p>
                        </div>
                      </div>
                    </section>
                    <div className="sizer">
                      <iframe
                        title="a"
                        className={thatIframe}
                        src={this.props.video}
                        frameborder="0"
                        allow="autoplay"
                        allowfullscreen
                      ></iframe>
                    </div>

                    <div className="container cardContainer">
                      <div className="cardHolder miniCardHolder">
                        {this.props.children}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )
          }
        </Media>
      </Layout>
    );
  }
}
export default SubCollection;
