import React from "react";
import "bulma/css/bulma.css";
import "../styles/itemCardStyle.css";

class ItemCard extends React.Component {
  render() {
    let mainText = "mainText";
    let theLine = "theLine";
    let secondText = "secondText";
    if (this.props.title == null && this.props.subtitle == null) {
      mainText += " hideThyElement";
      theLine += " hideThyElement";
      secondText += " hideThyElement";
    } else if (this.props.title == null) {
      mainText += " hideThyElement";
      theLine += " hideThyElement";
    } else if (this.props.subtitle == null) {
      secondText += " hideThyElement";
      theLine += " hideThyElement";
    } else {
      mainText = "mainText";
      theLine = "theLine";
      secondText = "secondText";
    }

    return (
      <div className="mainCard">
        <div className="headSection">
          <img className="imageSection" src={this.props.image} alt="" />

          <div className="borderSection"></div>
        </div>
        <div className="bodySection">
          <p className={mainText}>{this.props.title}</p>
          <hr className={theLine} />
          <p className={secondText}>{this.props.subtitle}</p>
        </div>
      </div>
    );
  }
}
export default ItemCard;
