import React from "react";
import SvgHeart from "../components/svgheart";
import Image from "../../static/designer.jpg";
import Image1 from "../../static/pinkheart.svg";
class FullSvgHeart extends React.Component {
  render() {
    let heartText = "heartText";
    if (this.props.bigText) {
      heartText = "bigHeartText";
    }
    return (
      <div className="svgHeartHolder">
        <SvgHeart
          xAxis={this.props.xAxis}
          yAxis={this.props.yAxis}
          className="thatHeart"
          image={this.props.image}
        ></SvgHeart>
        <img className="svgHeart" src={Image1} alt=""></img>
        <p className={heartText}>{this.props.title}</p>
      </div>
    );
  }
}

export default FullSvgHeart;
